/**
 * HTML
 */
body {
  background-color:#111111 !important;
  font-family: "Playfair Display SC";
  color: white !important;
}

h1, h2, h3, h4, h5, h6, p {
	color: white !important;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  text-align: center !important;
}

footer {
  background-color:#111111 !important;
}

/**
 * Bootstrap 5
 */
.navbar {
  background-color:rgba(17, 17, 17, 0.5) !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.carousel-inner .carousel-item{
    padding-left: 2rem;
    padding-right: 2rem;
}

.carousel-control-prev, .carousel-control-next{
    background-color: #818181;
    width: 6vh;
    height: 6vh;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

@media (min-width: 768px) {
  .carousel-inner {
    display: flex;
  }
  .carousel-item {
    margin-right: 0;
    flex: 0 0 33.333333%;
    display: block;
  }
}

/**
 * Custom Generic
 */
.section-bg-img {
	background-color: #111111;
  background-size: cover;
  background-position: center center;
	height: 100vh;
}

.section-bg-video {
	background-color: #111111;
	height: 100vh;
	padding: 0 !important;
	margin: 0;
}

.container-stretched {
  width: 100%;
  height: 100%;
  position: relative;
}

.element-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.space-20 {
  height: 20px;
}

.btn-download {
  width: 192px;
  height: 64px;
  margin: 0;
  padding: 0;
}

.btn-download:hover {
  opacity: 0.8;
}

.btn-facebook{
  background-color: #4267B2;
  margin: 0.5rem;
}

.btn-twitter {
  background-color: #1DA1F2;
  margin: 0.5rem;
}

.video-container {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

iframe {
  position: relative;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}

.video-container-overlay {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  color: black;
  left: 50%;
  top: -50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .title {
    font-size: 3rem;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 6rem;
  }
}

@media (min-aspect-ratio: 16/9) {
  .video-container iframe {
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-container iframe {
    width: 177.78vh !important;
  }
}

/**
 * Custom Specific
 */
.bg-home {
  background-image: url("../img/cover-arcitecture.jpg");
}

.bg-word-master {
  background-image: linear-gradient(180deg, #696969 0%, #000000 100%);
}

.bg-nexus-metaverse {
  background-image: url("../img/metaverse-1-scaled.jpg");
}

.bg-infopedia {
  background-image: linear-gradient(180deg, #696969 0%, #000000 100%);
}

.bg-slots {
  background-image: linear-gradient(180deg, #696969 0%, #000000 100%);
}

.bg-about {
  background-image: url("../img/vbg-4.jpg");
}